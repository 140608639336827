.MakerCard {
    display: grid;
    justify-items: center;
}

.MakerCard img {
    mix-blend-mode: var(--maker-card-img-blend-mode, normal);
}

.MakerImage {
  object-fit: contain;
}

.Description > div {
  display:grid;
  gap: var(--gap, .5rem);
}
.Description > span {
  white-space: pre-wrap;
}
.Description > div > p {
  white-space: pre-wrap;
}