.MakerName {
    display: inline-grid;
    justify-items: center;
    grid-template-columns:
        [nationality-start makerId-start] 24px [nationality-end] 6px [maker-start] max-content [maker-end makerId-end];
    grid-template-rows:
        [maker] max-content [makerId] 24px;
    width: max-content;
}

.Nationality {
    grid-area: maker / nationality;
}

.Name {
    grid-area: maker;
    font-size: 0.7rem;
    line-height: 150%;
    align-self: flex-start;
}

.MakerId {
    grid-area: makerId;
    font-size: 0.5rem;
    display: block;
}